.file-upload-container {
  padding: 30px;
  max-width: 600px;
  margin: auto;
}

.drop-area {
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  transition: border-color 0.3s ease;
}

.xkt-drop {
  border: 2px solid #4caf50;
}

.json-drop {
  border: 2px solid #2196f3;
}

.drop-area:hover {
  border-color: #333;
}

.file-name {
  font-weight: bold;
  color: #333;
}

.cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #f44336;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.cancel-button:hover {
  background-color: #d32f2f;
}

.drop-message {
  color: #555;
}

.upload-button-container {
  text-align: center;
}

.upload-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #4caf50;
  color: #ffffff;
  cursor: pointer;
  font-size: 18px;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.upload-button:disabled {
  background-color: #9e9e9e;
  cursor: not-allowed;
}

.upload-button:hover:not(:disabled) {
  background-color: #388e3c;
}

.progress-bar-container {
  margin-top: 30px;
  text-align: center;
}

.progress-bar {
  width: 100%;
  height: 30px;
  background-color: #ddd;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.2);
  position: relative;
}

.progress-bar-fill {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.5s ease-in-out;
  border-radius: 15px 0 0 15px;
}

.progress-bar-text {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: bold;
}
