.search-ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  color: #BFBFBF;
}

.search-li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 41px;
  border-bottom: 1px solid #1D1D1D;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  padding-right: 10px;
  padding-left: 10px;
  box-sizing: border-box;
}

.search-button {
  box-sizing: border-box;
  padding: 10px 40px 0px 40px;
}