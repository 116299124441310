.l-sidebar {
  position: absolute;
  width: 460px;
  top: 0%;
  left: 0px;
  background-color: #232323;
  direction: ltr;
  z-index: 20;
  height: 100vh;
  transform: translateX(-460px);
  transition: transform 0.2s ease-in-out;
  border: 1px solid #383838;
  color: #bfbfbf;
  box-sizing: border-box;
  padding: 2px 0px 10px 0px;
}

.l-sidebar.toggle {
  transform: translateX(0);
}

.sidebar-header {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 72px;
  padding: 2px 0px 2px 10px;
  box-sizing: border-box;
}

.sidebar-header-name {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  height: 25px;
  flex: 9;
  text-align: start;
}

.sidebar-header-cancel-btn {
  flex: 1;
  height: 21px;
}

.sidebar-header-cancel-btn:hover {
  cursor: pointer;
  transform: scale(1.1);
  color: #ffffff;
}

.seperate-line {
  border: 1px solid #444444;
  width: 100%;
  box-sizing: border-box;
}

.sidebar-body {
  box-sizing: border-box;
  padding: 2px;
  overflow-y: auto;
  overflow-x: auto;
  height: calc(100vh - 75px - 65px);
}

.sidebar-body-app {
  box-sizing: border-box;
  padding: 2px;
  overflow-y: auto;
  overflow-x: auto;
  height: calc(100vh - 78px);
}
