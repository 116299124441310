.issue-track-tab-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 70px;
  box-sizing: border-box;
}

.issue-track-tab-header-name {
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 100%;
  height: 70%;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  box-sizing: border-box;
  padding: 8px;
}

/* Tab list styles */
.issue-track-tab-list {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 30%;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #bfbfbf;
}

.tabs {
  flex: 1;
  text-align: center;
  width: 100%;
}

.tabs:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.tabs.active {
  border-bottom: 1px solid #ff00ba;
}

/* Tab content styles */
.issue-track-tab-content {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
  height: 100%;
}
