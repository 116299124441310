.model-comparison-window {
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: #f5f5f5;
}

.left-panel {
  width: 300px;
  height: 100%;
  border-right: 1px solid #ddd;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.version-selector {
  padding: 1rem;
  border-bottom: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.selector-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.selector-group label {
  font-size: 0.875rem;
  font-weight: 600;
  color: #333;
}

.selector-group select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.875rem;
}

.button-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.refresh-button {
  flex: 1;
  padding: 0.75rem;
  background-color: #0066cc;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
}

.refresh-button:hover {
  background-color: #0052a3;
}

.right-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.viewers-container {
  height: 50%;
  display: flex;
  gap: 1rem;
  padding: 1rem;
  background-color: #fff;
}

.viewer-panel {
  flex: 1;
  position: relative;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-width: 0;
}

.viewer-canvas {
  width: 100%;
  height: 100%;
}

.version-label {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.875rem;
}

.tree-view {
  flex: 1;
  padding: 1rem;
  overflow-y: auto;
}

.tree-view h3 {
  margin-top: 0;
  margin-bottom: 1rem;
  color: #333;
}

.comparison-tables {
  flex: 1;
  display: flex;
  padding: 1rem;
  gap: 1rem;
  overflow-y: auto;
  background-color: #fff;
}

.table-section {
  flex: 1;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 1rem;
  min-width: 0;
  display: flex;
  flex-direction: column;
}

.table-section h3 {
  margin: 0.5rem 0;
  padding: 8px;
  border-radius: 4px;
  color: white;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 0.5rem;
}

/* th,
td {
  padding: 0.5rem;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f5f5f5;
  font-weight: 600;
} */

/* Table header colors */
.table-section:nth-child(1) h3 {
  background-color: #2e7d32;
}

.table-section:nth-child(2) h3 {
  background-color: #c62828;
}

.table-section:nth-child(3) h3 {
  background-color: #ffa000;
}

/* Toggle Switch styles */

.compare-switch {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.compare-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.compare-slider {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 60px;
  color: #666;
  font-size: 14px;
}

.compare-slider:before {
  content: "";
  position: absolute;
  left: 0;
  height: 24px;
  width: 48px;
  background-color: #ccc;
  border-radius: 12px;
  transition: 0.4s;
}

.compare-slider:after {
  content: "";
  position: absolute;
  left: 4px;
  height: 16px;
  width: 16px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

.compare-switch input:checked + .compare-slider:before {
  background-color: #2196f3;
}

.compare-switch input:checked + .compare-slider:after {
  transform: translateX(24px);
}

.compare-switch input:focus + .compare-slider:before {
  box-shadow: 0 0 1px #2196f3;
}

.compare-switch input:disabled + .compare-slider:before {
  background-color: #ddd;
  cursor: not-allowed;
}

.compare-switch input:disabled + .compare-slider {
  color: #999;
  cursor: not-allowed;
}

.error-message {
  padding: 1rem;
  margin: 1rem;
  background-color: #ffebee;
  color: #c62828;
  border: 1px solid #ef9a9a;
  border-radius: 4px;
}

.refresh-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

select:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.info-message {
  font-size: 0.875rem;
  color: #666;
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: #e3f2fd;
  border-radius: 4px;
  border: 1px solid #90caf9;
}
