.mycustom-button {
    width: 100%;
    height: 30px;
    border: 1px solid #BFBFBF;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #BFBFBF;
    background-color: #232323;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3px;
    border-radius: 4px;
}

.mycustom-button:hover {
    border: 1px solid #FF00BA;
    background-color: #232323;
}

.mini-button {
    width: 100%;
    height: 32px;
    border: 1px solid #BFBFBF;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #BFBFBF;
    background-color: #232323;
}

.mini-button:hover {
    border: 1px solid #FF00BA;
}

/* Hide the default checkbox */
.custom-checkbox input {
    display: none;
}

/* Style the checkmark */
.custom-checkbox {
    display: inline-block;
    position: relative;
    cursor: pointer;
    font-size: 16px;
    width: 16px;
    height: 16px;
}

/* Background, size, and border-radius */
.custom-checkbox .checkmark {
    width: 16px;
    /* Adjust size */
    height: 16px;
    /* Adjust size */
    background-color: #1D1D1D;
    /* Background color */
    border: 1px solid #444444;
    /* Border color */
    border-radius: 4px;
    /* Adjust border-radius */
    position: absolute;
    top: -3;
    left: 0;
    transition: background-color 0.2s ease;
}

/* Checkmark when checked */
/* .custom-checkbox input:checked+.checkmark {
    background-color: #4caf50;
    border-color: #4caf50;
  } */

.custom-checkbox .checkmark::after {
    content: "";
    position: absolute;
    display: none;
    left: 4px;
    /* Adjust for checkmark position */
    top: -1px;
    /* Adjust for checkmark position */
    width: 6px;
    /* Checkmark size */
    height: 12px;
    /* Checkmark size */
    border: solid #FF00BA;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

/* Show checkmark when checked */
.custom-checkbox input:checked+.checkmark::after {
    display: block;
}

/* Hide the default calendar icon */
input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
    /* Change color (optional) */
    opacity: 1;
    /* Ensure visibility */
}



input[type="date"]:hover {
    border: 1px solid #FF00BA;
}

input[type="date"]:focus {
    border: 1px solid #FF00BA;
}


/* Default styles for the input */
input[type="date"] {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #232323;
    color: #bfbfbf;
    transition: border 0.3s ease;
    /* Smooth transition */
}