/* wwwroot/ReactCompare/styles/reactCompare.css */
/* Maintain consistency with your existing styles */
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
}

.react-compare-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.react-compare-button {
  padding: 8px;
  background-color: #4caf50; /* Match your existing button styles */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.react-compare-button:hover {
  background-color: #45a049;
}
