.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 21;
}

.modal {
  margin: auto;
  border-radius: 10px;
  border: 1px solid #383838;
  color: #bfbfbf;
  background-color: #232323;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 72px;
  padding: 10px 20px;
  box-sizing: border-box;
}

.modal-header-name {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  height: 25px;
  text-align: start;
}

.modal-header-cancel-btn {
  height: 21px;
}

.modal-header-cancel-btn:hover {
  cursor: pointer;
  transform: scale(1.1);
  color: #ffffff;
}

.modal-line {
  border: 1px solid #444444;
  width: 100%;
}

.modal-body {
  box-sizing: border-box;
  padding: 20px;
}
