/* table,
td,
th {
  border: 1px solid #ddd;
  text-align: center;
}

table {
  border-collapse: collapse;
  width: 100%;
  font-size: small;
} */

/* th,
td {
  padding: 5px;
} */

.custom-button {
  border: none;
  color: white;
  padding: 10px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  background-color: #04aa6d;
}

.property-row:hover {
  background-color: #cccccc;
}

/* ----------------------------------------------------------------------------------------------------------*/
/* TreeViewPlugin */
/* ----------------------------------------------------------------------------------------------------------*/
/* #treeViewContainer {
    pointer-events: all;
    height: 90%;
    overflow-y: scroll;
    overflow-x: hidden;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.2);
    color: black;
    top: 80px;
    z-index: 200000;
    float: left;
    left: 0;
    padding-left: 10px;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    width: 440px;
  }
  
  #treeViewContainer ul {
    list-style: none;
    padding-left: 1.75em;
    pointer-events: none;
  }
  
  #treeViewContainer ul li {
    position: relative;
    width: 350px;
    pointer-events: none;
    padding-top: 3px;
    padding-bottom: 3px;
    vertical-align: middle;
  }
  
  #treeViewContainer ul li a {
    background-color: #eee;
    border-radius: 50%;
    color: #000;
    display: inline-block;
    height: 1.5em;
    left: -1.5em;
    position: absolute;
    text-align: center;
    text-decoration: none;
    width: 1.5em;
    pointer-events: all;
  }
  
  #treeViewContainer ul li a.plus {
    background-color: #ded;
    pointer-events: all;
  }
  
  #treeViewContainer ul li a.minus {
    background-color: #eee;
    pointer-events: all;
  }
  
  #treeViewContainer ul li a:active {
    top: 1px;
    pointer-events: all;
  }
  
  #treeViewContainer ul li span:hover {
    color: white;
    cursor: pointer;
    background: black;
    padding-left: 2px;
    pointer-events: all;
  }
  
  #treeViewContainer ul li span {
    display: inline-block;
    width: calc(100% - 50px);
    padding-left: 2px;
    pointer-events: all;
    height: 23px;
  } */

/* #treeViewContainer .highlighted-node {
    Appearance of node highlighted with TreeViewPlugin#showNode()
    border: black solid 1px;
    background: yellow;
    color: black;
    padding-left: 1px;
    padding-right: 5px;
    pointer-events: all;
  } */

/* #treeViewContainer {
    pointer-events: all;
    height: calc(100% - 80px);
    overflow-y: scroll;
    overflow-x: hidden;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.2);
    color: black;
    top: 80px;
    z-index: 200000;
    float: left;
    left: 0;
    padding-left: 16px;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    width: 450px;
}

#treeViewContainer button, 
#treeViewContainer input {
    background-color: transparent;
    border-style: none;
}

#treeViewContainer ul {
    list-style: none;
    padding-left: 18px;
}

#treeViewContainer ul li div {
    padding-left: 8px;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
}

#treeViewContainer ul li div.v-treeview-node__level{
    width: 24px;
    display: inline-flex;
}

#treeViewContainer ul li button {
    display: inline-flex;
}

#treeViewContainer ul li input {
    margin-left: 4px;
    margin-right: 4px;
    width: 18px;
    height: 18px;
}

#treeViewContainer ul li input[type=checkbox] {
    position: relative;
    cursor: pointer;
}

#treeViewContainer ul li input[type=checkbox]:before {
    content: "";
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    border: 2px solid rgba(0, 0, 0, 0.54);
    border-radius: 3px;
    background-color:#fff;
}

#treeViewContainer ul li input[type=checkbox]:checked:before {
    content: "";
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    border: 2px solid #398684;
    background-color: #398684 !important;
}
#treeViewContainer ul li input[type=checkbox]:checked:after {
    content: "";
    display: block;
    width: 8px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 6px;
}

#treeViewContainer ul li span {
    vertical-align: middle;
    margin-left: 6px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1;
}

.v-icon {
    align-items: center;
    display: inline-flex;
    font-feature-settings: "liga";
    height: 1em;
    justify-content: center;
    letter-spacing: normal;
    line-height: 1;
    position: relative;
    text-indent: 0;
    text-align: center;
    user-select: none;
    vertical-align: middle;
    width: 1em;
    min-width: 1em;
    font-size: 1.5em;
    cursor: pointer;
}
.v-ifcicon {
    margin-left: 6px;
    font-size: 1.5em;
    color: #398684;
} */

.tree {
  list-style: none;
  padding: 0;
  margin: 0;
  background: #232323;
  border-radius: 8px;
  padding: 10px 15px;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

/* .tree li {
  padding-left: 1rem;
  position: relative;
} */

/* .tree li::before {
  content: '';
  position: absolute;
  top: 12px;
  left: 0;
  border-left: 1px solid #ddd;
  height: 100%;
  width: 1px;
}

.tree li::after {
  content: '';
  position: absolute;
  top: 12px;
  left: -1rem;
  border-top: 1px solid #ddd;
  width: 1rem;
}

.tree li:last-child::before {
  height: 12px;
} */

summary {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 6px 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

summary:hover {
  background-color: #e6f7ff;
  color: #007BFF;
}

summary::before {
  content: '▶';
  display: inline-block;
  margin-right: 8px;
  font-size: 0.8rem;
  transition: transform 0.3s ease;
}

details[open] summary::before {
  transform: rotate(90deg);
}

ul {
  margin-top: 8px;
  padding-left: 1rem;
  list-style: none;
}


/* li ul {
  padding-left: 10px;
  border-left: 1px dashed #ddd;
} */

.tree span:hover {
  background-color: #e6f7ff;
  color: #007BFF;
}

.tree input[type="checkbox"] {
  margin-right: 6px;
}

/* .tree label {
  display: flex;
  align-items: start;
} */

.hideTreeColor {
  color: #cbc3c3
}

.showTreeColor {
  color: #BFBFBF;
  margin-top: -4px;
  text-align: start;
  padding-left: 10px;
}

.list-item {
  display: flex;
  align-items: start;
}

.details-item {
  margin-top: -10px;
}

.tree-child {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: start
}