.viewer-default:hover {
  content: url(../../asset/defaultView_variant.svg);
}

.box-property-option:hover {
  /* border-left: 3px solid #FD13B1; */
  background-color: #3b3b3b;
}

.savepoints-toolbar {
  width: 100%;
  display: flex;
  justify-content: center;
}

.appearence-template-ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  color: #bfbfbf;
}

.appearance-template-li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 41px;
  border-bottom: 1px solid #1d1d1d;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  padding-right: 10px;
  padding-left: 10px;
  box-sizing: border-box;
}

.appearance-template-li:hover {
  border-left: 3px solid #fd13b1;
  background-color: #3b3b3b;
}

.box-property-container {
  display: flex;
  flex-direction: column;
  color: #bfbfbf;
  width: 90%;
  text-align: start;
  gap: 5px;
  margin-top: 5px;
}

.transparency-btn-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.transparency-btn-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
}

.transparency-btn-save {
  width: 70%;
}