  .imagePlane-button {
    border: none;
    color: #BFBFBF;
    padding: 10px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    background-color: #04aa6d;
    width: 300px;
  }

  button:hover {
    background: #89c3d6;
  }

  .slider-container {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
  }

  .slider-label {
    font-size: 1.2rem;
    margin-bottom: 5px;
    color: #BFBFBF;
    width: 20%;
  }

  .slider {
    -webkit-appearance: none;
    appearance: none;
    width: 60%;
    height: 8px;
    background: #ddd;
    border-radius: 20px;
    outline: none;
    transition: background 0.3s ease;
    cursor: pointer;
  }

  .slider:hover {
    background: linear-gradient(90deg, #4caf50, #ff9800);
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: #FF00BA;
    border-radius: 50%;
    border: 3px solid #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    transition: transform 0.2s ease;
  }

  .slider::-webkit-slider-thumb:hover {
    transform: scale(1.2);
    background: #FF00BA;
  }

  .slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: #4caf50;
    border-radius: 50%;
    border: 3px solid #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    transition: transform 0.2s ease;
  }

  .slider::-moz-range-thumb:hover {
    transform: scale(1.2);
    background: #ff9800;
  }

  .slider-value {
    font-size: 1.1rem;
    color: #BFBFBF;
    width: 20%;
  }

  .imagePlane-apply-btn {
    margin: 15px 0 5px 0;
    width: 90%;
  }

  .imagePlane-btn-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 30px 30px 30px 10px;
    gap: 10px;
  }

  .imagePlane-btn-add {
    width: 20%;
  }

  .imagePlane-btn-dropdown {
    width: 80%;
  }

  .imagePlane-activeView-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 30px 30px 30px 10px;
    gap: 15px;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
  }

  .imagePlane-activeView {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .imagePlane-activeView-checkbox {
    width: 15%;
  }

  .imagePlane-activeView-input {
    width: 75%;
  }

  .imagePlane-activeView-delete {
    width: 10%;
    text-align: end;
    color: #FF00BA
  }

  .annotation-label {
    position: absolute;
    max-width: 250px;
    min-height: 50px;
    padding: 8px;
    padding-left: 12px;
    padding-right: 12px;
    background: #ffffff;
    color: #000000;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 8px;
    border: #ffffff solid 2px;
    box-shadow: 5px 5px 15px 1px #000000;
    z-index: 90000;
  }

  .annotation-label:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 8px 12px 8px 0;
    border-color: transparent white;
    display: block;
    width: 0;
    z-index: 1;
    margin-top: -11px;
    left: -12px;
    top: 20px;
  }

  .annotation-label:before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 9px 13px 9px 0;
    border-color: transparent #ffffff;
    display: block;
    width: 0;
    z-index: 0;
    margin-top: -12px;
    left: -15px;
    top: 20px;
  }

  .annotation-title {
    font: normal 20px arial, serif;
    margin-bottom: 8px;
  }

  .annotation-desc {
    font: normal 14px arial, serif;
  }