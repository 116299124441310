.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #232323;
  justify-content: space-between;
  height: 41px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  border-bottom: 1px solid #1D1D1D;
}

.item-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.item-rect-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item-rect-btn:hover {
  content: url(../../asset/rect_variant.svg)
}