.clash-detection-tab {
  position: absolute;
  left: 0px;
  height: 100%;
}

.clash-detection-tab-toolbar {
  background-color: #444444;
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.clashdetectiontab-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modal-ul {
  list-style-type: none;
  padding: 0;
  margin: 0px 0px 0px 45px;
}

.modal-li {
  border-bottom: 1px solid #ddd;
  margin-top: -1px;
  /* Prevent double borders */
  /* background-color: #f6f6f6; */
  padding: 8px;
  text-decoration: none;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  position: relative;
  text-align: start;
  gap: 5px;
}

.modal-li-clicked {
  border-bottom: 1px solid #ddd;
  margin-top: -1px;
  /* Prevent double borders */
  /* background-color: #f6f6f6; */
  padding: 8px;
  text-decoration: none;
  font-size: 14px;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: start;
  position: relative;
  text-align: start;
  gap: 5px;
  background-color: #eee;
}

.modal-li:hover {
  background-color: #eee;
}

.modal-li:hover {
  background-color: #eee;
}

.modal-body-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #BFBFBF;
}

.save-btn:hover {
  content: url(../../asset/save_hover.png);
}

.modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  font-weight: bold;
  border-bottom: 1px solid #cccccc;
}

.modal-close {
  width: 40px;
}

.modal-body {
  padding: 20px;
}

.clash-detection-tab-modal {
  width: 600px;
  height: 500px;
  display: flex;
  flex-direction: column;
}

.modal-body-list-colum {
  width: 50%;
  height: 300px;
  overflow-y: scroll;
}

.clash-detection-tab-ul {
  list-style-type: none;
  padding: 0;
  margin: 0px 0px 0px 45px;
  width: 90%;
}

.clash-detection-tab-li-item {
  border: 1px solid #ddd;
  border-left: none;
  margin-top: -1px;
  /* Prevent double borders */
  /* background-color: #f6f6f6; */
  padding: 8px;
  text-decoration: none;
  font-size: 14px;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: start;
  position: relative;
  text-align: start;
  gap: 5px;
}

.clash-detection-tab-li-item:hover {
  background-color: #eee;
}

.clashdetection-issue-button {
  border: none;
  /* color: white; */
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
  background-color: #04aa6d;
}

.issue-type1 {
  color: rgb(240, 217, 9);
  font-weight: 300;
  border: 2px solid rgb(240, 217, 9);
  border-radius: 10px;
  padding: 1px 5px 1px 5px;
  background-color: #cccccc;
}

.issue-type2 {
  color: red;
  font-weight: 300;
  border: 2px solid red;
  border-radius: 10px;
  padding: 1px 5px 1px 5px;
  background-color: #cccccc;
}

.issue-option {
  border: 1px solid #cccccc;
  display: flex;
  flex-direction: column;
  width: 90%;
  padding-left: 20px;
  height: 200px;
}

.issue-option-item {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.issue-option-item-colum2 {
  display: flex;
  flex-direction: column;
  justify-content: start;
  position: relative;
}

.stamp-list {
  position: absolute;
  top: 30px;
  width: 250px;
  height: 300px;
  overflow-y: scroll;
  border: 1px solid #cccccc;
  border-radius: 15px;
  padding: 5px 10px;
  font-size: small;
  background-color: white;
  z-index: 1;
}

.stamp-list-item {
  display: flex;
  flex-direction: row;
}

.stamp-list-item:hover {
  background-color: #eee;
}

.visible-show {
  visibility: show;
}

.visible-hide {
  visibility: hidden;
  position: absolute;
}

.stamp-btn-width {
  width: 100px;
}

.issue-comment-input {
  width: 100%;
  height: 30px;
  border: none;
  border-bottom: 2px solid #cccccc;
  background-color: #eee;
}

.clash-detection-annotation-marker {
  /* color: blue; */
  line-height: 1.8;
  text-align: center;
  font-family: "monospace";
  font-weight: bold;
  position: absolute;
  width: 45px;
  height: 25px;
  border-radius: 10px;
  /* border: 4px solid blue; */
  background: white;
  visibility: hidden;
  box-shadow: 5px 5px 15px 1px #000000;
  z-index: 0;
}



.clash-detection-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.clash-detection-table-col1 {
  width: 10%;
}

.clash-detection-table-col2 {
  width: 80%;
}

.clash-detection-table-col3 {
  width: 80%;
}

.clash-detection-table-col4 {
  width: 10%;
}

.clash-detection-modal-container {
  overflow-y: auto;
  height: calc(70vh - 120px);
}

.clash-detection-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  gap: 15px;
  padding: 10px;
}

.clash-detection-test-item {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.clash-detection-test-label {
  width: 40%;
}

.clash-detection-test-btn {
  width: 60%;
}

.dropup-icon {
  width: 6px;
  height: 6px;
  border-right: 1px solid #BFBFBF;
  border-bottom: 1px solid #BFBFBF;
  transform: rotate(-135deg);
  border: 1 0 0 1;
  cursor: pointer;
}

.clash-detection-table-name {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  height: inherit;
  box-sizing: border-box;
  padding-left: 10px;
  gap: 10px;
  text-align: start;
}

.clash-detection-table-status {
  display: flex;
  justify-content: center;
  align-items: center;
}

.clash-detection-status-stamp {
  line-height: 1.8;
  text-align: center;
  font-family: "monospace";
  font-weight: bold;
  width: 45px;
  height: 25px;
  border-radius: 10px;
  border: 2px solid;
  background: white;
  background-color: white;
  color: red;
  border-color: red;
}