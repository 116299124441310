.appearance-profile-container {
  display: flex;
  flex-direction: column;
  padding: 15px 5px 10px 5px;
  box-sizing: border-box;
  gap: 20px;
}

.appearance-profile-ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.appearance-profile-li {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 20px 0 0 0;
}

.appearance-profile-checkbox {
  width: 10%;
}

.appearance-profile-dropdown {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
}

.appearance-profile-preview {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.appearance-profile-add-btn {
  width: 40%;
  display: flex;
  justify-content: start;
  margin-top: 20px;
}

.appearance-profile-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
}

.transparency-btn-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;

}

.transparency-btn-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
}

.transparency-btn-save {
  width: 70%;
}

/*Save Modal*/
.ap-save-btn-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.ap-save-btn-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 70%;
  text-align: start;
}

.ap-save-btn-save {
  width: 70%;
}