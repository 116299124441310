.header {
  height: 52px;
  width: 100%;
  background-color: #232323;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  border-bottom: 1px solid #3E3E3E;
}

.logo {
  width: 69px;
  height: 51px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  border-right: 1px solid #3E3E3E;
}

.header-dropdown-icon {
  width: 6px;
  height: 6px;
  border-right: 1px solid #BFBFBF;
  border-bottom: 1px solid #BFBFBF;
  transform: rotate(45deg);
  cursor: pointer;
}

.header-dropdown-icon:hover {
  border-right: 1px solid #FF00BA;
  border-bottom: 1px solid #FF00BA;
}

.header-toolbar {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding-left: 5px;
}

.header-toolbar-box {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-toolbar-box:disabled {
  background-color: red;
}

.measurement:hover {
  content: url(../../asset/measurement_variant.svg);
}

.measurement-clicked {
  content: url(../../asset/measurement_variant.svg);
}

.cut:hover {
  content: url(../../asset/cut_variant.svg);
}

.cut-clicked {
  content: url(../../asset/cut_variant.svg);
}

.save:hover {
  content: url(../../asset/save_variant.svg);
}

.save-clicked {
  content: url(../../asset/save_variant.svg);
}

.restart:hover {
  content: url(../../asset/restart_variant.svg);
}

.lock-clicked {
  content: url(../../asset/lock_variant.svg);
}

.lock:hover {
  content: url(../../asset/lock_variant.svg);
}

.redo-clicked {
  content: url(../../asset/redo_variant.svg)
}

.redo:hover {
  content: url(../../asset/redo_variant.svg)
}

.header-toolbar-controller {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.header-toolbar-controller-elipses {
  display: flex;
  flex-direction: column;
  gap: 1px;
  box-sizing: border-box;
}

.header-toolbar-controller-elipses-row {
  display: flex;
  flex-direction: row;
  gap: 1px;
}

.header-toolbar-controller-elipses-row>span {
  border: 2px solid #3D3D3D;
  border-radius: 2px;
}

.header-toolbar-controller-line {
  height: 30px;
  border-right: 1px solid #3D3D3D;
}

.hide {
  visibility: hidden;
}

.show {
  visibility: visible;
}

.measurement-box-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.measurement-box-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding: 20px;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
}

.measurement-box-minimise {
  text-align: end;
  color: #FF00BA;
}

.measurement-ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  color: #BFBFBF;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
}

.measurement-li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #1D1D1D;
  text-decoration: none;
  padding-right: 10px;
  padding-left: 10px;
  box-sizing: border-box;
}

.measurement-li:hover {
  border-left: 5px solid #FD13B1;
  background-color: #3B3B3B;
}

.sectioncut-box-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.sectioncut-box-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding: 20px;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
}

.sectioncut-ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  color: #BFBFBF;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
}

.sectioncut-box-minimise {
  text-align: end;
  color: #FF00BA
}

.sectioncut-ul {
  padding: 0;
  margin: 0;
  width: 100%;
  color: #BFBFBF;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;

}

.sectioncut-li {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #232323;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  border-bottom: 1px solid #1D1D1D;
}

.sectioncut-name {
  text-align: start;
  flex: 6;
}

.sectioncut-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  gap: 8px;
  flex: 4;
}

.sectioncut-switch {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.sectioncut-lock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.sectioncut-redo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.menubox-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.menubox-body {
  width: 100%;
  color: #BFBFBF;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
}

.menubox-li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 49px;
  border-bottom: 1px solid #1D1D1D;
  text-decoration: none;
  padding-right: 10px;
  padding-left: 10px;
  box-sizing: border-box;
}

.menubox-li:hover {
  border-left: 5px solid #FD13B1;
  background-color: #3B3B3B;
}

.clicked {
  border-left: 5px solid #FD13B1;
  /* background-color: #3B3B3B; */
}

.updateModel-btn {
  content: url(../../asset/modelUpdate_variant.svg);
}