.project-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 20px;
    overflow-y: scroll;
    height: 100vh;
    background-color: black;
    color: white;
}

.project {
    display: flex;
    flex-direction: column;
    margin: 30px;
    border: 10px solid gray;
    height: 300px;
}

.project:hover {
    cursor: pointer;
    border: 10px solid #ff00ba;
    transform: scale(1.1);
}