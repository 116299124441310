.menubar {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 78px;
  height: calc(100vh - 52px);
  background-color: #232323;
  box-sizing: border-box;
  padding: 20px 10px 0px 10px;
  z-index: 20;
}

.menubar-btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

.dropdown-icon {
  width: 6px;
  height: 6px;
  border-right: 1px solid #BFBFBF;
  border-bottom: 1px solid #BFBFBF;
  transform: rotate(135deg);
}

.leftPush-btn {
  display: flex;
  flex-direction: row;
}

.menubar-btn-line {
  border: 1.9px solid #BFBFBF;
  width: 20px;
}

.menubar-btn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 18px;
}


/* .menubar-btn-line {
  margin: 5px;
  width: 20px;
  height: 2px;
  background-color: white;
} */

/* .menubar-btn {
  width: 70px;
  height: 70px;
  border-radius: 50px;
  background-color: #516b77;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

.menubar-btn-vertical {
  transition: transform 0.3s;
  transform: rotate(90deg);
}

.menubar-btn-horizonal {
  transition: transform 0.3s;
  transform: rotate(180deg);
}

/* .menubar-btn-line {
  margin: 5px;
  width: 50px;
  height: 5px;
  background-color: white;
} */

.menubar-dropdown-show {
  height: 490px;
  overflow-y: hidden;
  transition: height 0.5s;
}

.menubar-dropdown-hidden {
  height: 0px;
  overflow-y: hidden;
  transition: height 0.5s;
}

.main-menu-annotation {
  margin-top: 50px;

}

.main-menu-annotation-stamp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
  gap: 10px;
  width: 100%;
}

.main-menu-annotation-label {
  width: 20%;
}

.main-menu-annotation-dropdown {
  width: 60%;
}

.main-menu-annotation-toggle {
  width: 20%;
}