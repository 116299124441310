table,
td,
th {
  border: 1px solid #1d1d1d;
  text-align: center;
}

.custom-table {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  overflow-x: scroll;
}

.table-row {
  height: 35px;
}

.table-col1 {
  width: 30%;
}

.table-col2 {
  width: 70%;
}

.table-col2-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.table-col2-row1 {
  width: 90%;
  box-sizing: border-box;
}

.table-col2-row2 {
  width: 10%;
}

.issue-track-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99999999;
}

.issue-track {
  width: 80%;
  height: 80%;
  border: 1px solid #383838;
  color: #bfbfbf;
  background-color: #232323;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.issue-track-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  width: 100%;
  height: 60px;
  padding: 15px;
  box-sizing: border-box;
}

.issue-track-header-name {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  height: 25px;
  flex: 9;
  text-align: start;
}

.issue-track-cancel-btn {
  margin: 20px;
}

.issue-track-cancel-btn:hover {
  cursor: pointer;
  transform: scale(1.1);
  color: #ffffff;
}

.issue-track-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.issue-track-list-container {
  width: 70%;
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
}

.issue-track-list {
  box-sizing: border-box;
  min-height: 550px;
  min-width: 1000px;
}

.issue-track-tab-container {
  width: 30%;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
}

.issue-track-tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  min-width: 370px;
  min-height: 550px;
}

.vertical-seperate-line {
  border-left: 1px solid #434343;
  height: 100%;
  min-height: 600px;
}

.issue-track-table-width {
  color: #bfbfbf;
  width: 1268;
}

.issue-track-table-row {
  height: 30px;
}

.issue-track-table-row:hover {
  /* background-color: #FF00BA; */
  background-color: #3b3b3b;
  cursor: pointer;
}

.table-row-clicked {
  background-color: #ff00ba;
}

.table-col-checkbox {
  width: 1%;
}

.table-col-id {
  width: 1%;
}

.table-col-new {
  width: 1%;
}

.table-col-title {
  width: 20%;
}

.table-col-stamp {
  width: 5%;
}

.table-col-status {
  width: 10%;
}

.table-col-type {
  width: 10%;
}

.table-col-assignee {
  width: 10%;
}

.table-col-duedate {
  width: 10%;
}

.table-col-startdate {
  width: 10%;
}

.table-col-level {
  width: 10%;
}

.table-col-grid {
  width: 7%;
}

.new-issue {
  width: 10px;
  height: 10px;
  background-color: #ff00ba;
  border: 1px solid #ffffff;
  border-radius: 10px;
}

.issue-track-stamp-OME {
  width: 37px;
  height: 16px;
  border: 1px solid #996f03;
  border-radius: 4px;
  color: #000000;
  background-color: #ffeb82;
}

.issue-track-stamp-1AR {
  width: 37px;
  height: 16px;
  border: 1px solid #990303;
  border-radius: 4px;
  color: #000000;
  background-color: #ff8282;
}

.issue-track-stamp-1AH {
  width: 37px;
  height: 16px;
  border: 1px solid red;
  border-radius: 4px;
  color: red;
  background-color: white;
}

.issue-track-stamp-1AV {
  width: 37px;
  height: 16px;
  border: 1px solid blue;
  border-radius: 4px;
  color: blue;
  background-color: white;
}

.issue-track-status-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.issue-track-status-Solved {
  width: 10px;
  height: 10px;
  background-color: #36f132;
  border: 1px solid #ffffff;
  border-radius: 10px;
}

.issue-track-status-Closed {
  width: 10px;
  height: 10px;
  background-color: #428dff;
  border: 1px solid #ffffff;
  border-radius: 10px;
}

.issue-track-status-InProgress {
  width: 10px;
  height: 10px;
  background-color: #ff8d24;
  border: 1px solid #ffffff;
  border-radius: 10px;
}

.issue-track-status-Open {
  width: 10px;
  height: 10px;
  background-color: #ff0000;
  border: 1px solid #ffffff;
  border-radius: 10px;
}

.filter-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.filter-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.filter-icon:hover {
  opacity: 0.7;
}

.filter-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  background: #212529;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 4px;
  margin-top: 4px;
}

.filter-dropdown {
  width: 150px;
  /* padding: 4px 8px; */
  /* border: 1px solid #ddd; */
  /* border-radius: 3px; */
  /* background: white; */
  /* cursor: pointer; */
}

.resizeHandle {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 4px;
  cursor: col-resize;
}

.resizeHandleHover {
  background-color: #0066cc;
}
