/* @keyframes rotateLine {

  0%,
  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
} */



/* 
.rotating-container {
  position: relative;
  border-radius: 50%;
  animation: rotate 2s infinite linear; 
}

.dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: 0 0; 
  border-radius: 50%;
  animation: move 2s infinite linear; 
}

.dot:nth-child(1) {
  transform: rotate(0deg) translateX(20px); 
  opacity: 0%;
}

.dot:nth-child(2) {
  transform: rotate(45deg) translateX(20px); 
  opacity: 10%;
}

.dot:nth-child(3) {
  transform: rotate(90deg) translateX(20px); 
  opacity: 20%;
}

.dot:nth-child(4) {
  transform: rotate(135deg) translateX(20px); 
  opacity: 30%;
}

.dot:nth-child(5) {
  transform: rotate(180deg) translateX(20px); 
  opacity: 40%;
}

.dot:nth-child(6) {
  transform: rotate(225deg) translateX(20px); 
  opacity: 50%;
}

.dot:nth-child(7) {
  transform: rotate(270deg) translateX(20px); 
  opacity: 60%;
}

.dot:nth-child(8) {
  transform: rotate(315deg) translateX(20px); 
  opacity: 70%;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */

.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99999999;
}

.customSpinnerElement {
  z-index: 9000;
  position: absolute;
  left: center;
  top: center;
  visibility: visible;
}

.spinner-fading-circle {
  background: transparent;
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.spinner-fading-circle .spinner-circle {
  width: 120%;
  height: 120%;
  position: absolute;
  left: 0;
  top: 0;
}

.spinner-fading-circle .spinner-circle:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #FF00BA;
  border-radius: 100%;
  animation: spinner-circleFadeDelay 1.2s infinite ease-in-out both;
}

.spinner-fading-circle .spinner-circle2 {
  transform: rotate(30deg);
}

.spinner-fading-circle .spinner-circle3 {
  transform: rotate(60deg);
}

.spinner-fading-circle .spinner-circle4 {
  transform: rotate(90deg);
}

.spinner-fading-circle .spinner-circle5 {
  transform: rotate(120deg);
}

.spinner-fading-circle .spinner-circle6 {
  transform: rotate(150deg);
}

.spinner-fading-circle .spinner-circle7 {
  transform: rotate(180deg);
}

.spinner-fading-circle .spinner-circle8 {
  transform: rotate(210deg);
}

.spinner-fading-circle .spinner-circle9 {
  transform: rotate(240deg);
}

.spinner-fading-circle .spinner-circle10 {
  transform: rotate(270deg);
}

.spinner-fading-circle .spinner-circle11 {
  transform: rotate(300deg);
}

.spinner-fading-circle .spinner-circle12 {
  transform: rotate(330deg);
}

.spinner-fading-circle .spinner-circle2:before {
  animation-delay: -1.1s;
}

.spinner-fading-circle .spinner-circle3:before {
  animation-delay: -1s;
}

.spinner-fading-circle .spinner-circle4:before {
  animation-delay: -0.9s;
}

.spinner-fading-circle .spinner-circle5:before {
  animation-delay: -0.8s;
}

.spinner-fading-circle .spinner-circle6:before {
  animation-delay: -0.7s;
}

.spinner-fading-circle .spinner-circle7:before {
  animation-delay: -0.6s;
}

.spinner-fading-circle .spinner-circle8:before {
  animation-delay: -0.5s;
}

.spinner-fading-circle .spinner-circle9:before {
  animation-delay: -0.4s;
}

.spinner-fading-circle .spinner-circle10:before {
  animation-delay: -0.3s;
}

.spinner-fading-circle .spinner-circle11:before {
  animation-delay: -0.2s;
}

.spinner-fading-circle .spinner-circle12:before {
  animation-delay: -0.1s;
}

@keyframes spinner-circleFadeDelay {

  0%,
  39%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}