.search-tab {
  position: absolute;
  left: 455px;
  height: 100%;
}

.modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  font-weight: bold;
  border-bottom: 1px solid #cccccc;
}

.modal-close {
  width: 40px;
}

.modal-close:hover {
  background-color: #ddd;
}

.searchtab-toolbar {
  background-color: #cccccc;
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.modal-body {
  padding: 20px;
}

.save-btn:hover {
  content: url(../../asset/save_hover.png);
}

.searchtab-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 80%;
}

.edit-btn:hover {
  content: url(../../asset/edit_search_hover.png);
}

.search-sets-modal-li-item {
  border: 1px solid #ddd;
  border-left: none;
  border-right: none;
  margin-top: -1px;
  /* Prevent double borders */
  /* background-color: #f6f6f6; */
  padding: 8px;
  text-decoration: none;
  font-size: 14px;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: start;
  position: relative;
  text-align: start;
  gap: 5px;
}

.search-sets-modal-ul {
  /* width: 100%; */
}

.search-sets-modal-li-item:hover {
  background-color: #eee;
}

.search-sets-modal-li-item:checked {
  background-color: #eee;
}