.color-picker-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99999999;
}

.color-picker {
  box-shadow: 0px 10px 15px rgba(31, 41, 55, 0.1), 0px 4px 6px rgba(31, 41, 55, 0.05);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 439px;
  width: 296px;
  text-align: center;
  justify-content: space-evenly;
  align-items: center;
  background-color: #232323;
  color: #BFBFBF;
  box-sizing: border-box;
  padding: 5px 0px 5px 0px;
}

.custom-pointer {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgb(248, 248, 248);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
}

.custom-slider {
  width: 8px;
  height: 8px;
  border-radius: 5px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
  background: #ffffff;
  transform: translateX(-2px);
  cursor: pointer
}

.hue-horizontal {
  border-radius: 100px;
}

.saturation {
  width: 262px;
  height: 129px;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
}

.swatch-circle {
  min-width: 20px;
  min-height: 20px;
  margin: 1px 2px;
  cursor: pointer;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
  border-radius: 50%;
}

.hue {
  height: 8px;
  position: relative;
  margin: 10px 0px;
  width: 262px;
  border-radius: 200px;
}

.hex-input {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 2px 0;
  width: 100px;
  height: 28px;
  box-sizing: border-box;
  gap: 2px;
}

.color-swatches {
  width: 262px;
  height: 39px;
  background-color: red;
  border-radius: 4px;
  margin-top: 5px;
}

.range-input {
  -webkit-appearance: none;
  width: 262px;
  height: 4px;
  border-radius: 3px;
  outline: none;
  /* transition: background 0.3s; */
}

.range-input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 4px;
  height: 16px;
  background: #ffffff;
  cursor: pointer;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}

.range-input::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}

.red-gradient {
  background: linear-gradient(90deg,
      #000000 0%,
      #4d0000 25%,
      #990000 50%,
      #cc0000 75%,
      #ff0000 100%);
}

.green-gradient {
  background: linear-gradient(90deg,
      #000000 0%,
      #004d00 25%,
      #009900 50%,
      #00cc00 75%,
      #00e600 100%);
}

.blue-gradient {
  background: linear-gradient(90deg,
      #000000 0%,
      #00004d 25%,
      #000099 50%,
      #0000cc 75%,
      #0000ff 100%);
}

.color-controller {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 262px;
  height: 39px;
}

.color-save-btn {
  width: 150px;
}

.color-picker-header {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 26px;
  padding: 0px 8px 0px 8px;
  box-sizing: border-box;
}

.color-picker-header-name {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  height: 25px;
  flex: 9;
  text-align: start;
  vertical-align: bottom;
}

.color-picker-header-cancel-btn {
  flex: 1;
  height: 21px;
}

.color-picker-header-cancel-btn:hover {
  cursor: pointer;
  transform: scale(1.1);
  color: #ffffff;
}