.sectionPlane-dropdown-show {
  position: absolute;
  top: 80px;
  left: 0px;
  height: 295px;
  overflow-y: hidden;
  transition: height 0.5s;
}
.sectionPlane-dropdown-hidden {
  position: absolute;
  top: 80px;
  left: 0px;
  height: 0px;
  overflow-y: hidden;
  transition: height 0.5s;
}
