.box-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #232323;
  color: #bfbfbf;
  box-sizing: border-box;
  z-index: 20;
}
