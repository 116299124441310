.thumbnail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  box-sizing: border-box;
  padding: 15px;
  height: 100%;
}

.setopacity-button {
  border: none;
  color: white;
  padding: 10px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  background-color: #04aa6d;
}

.thumbnail-camera-pivot-marker {
  color: #ffffff;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 15px;
  border: 1px solid #ebebeb;
  background: black;
  visibility: hidden;
  box-shadow: 1px 1px 5px 1px #000000;
  z-index: 10000;
  pointer-events: none;
}