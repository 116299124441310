.mainpage {
  position: relative;
  height: 100%;
}
.model-viewer {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
}
