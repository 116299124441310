.login-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100vh;
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: black;
    color: white
  }
  
  .login-wrapper p {
    font-size: 0.85rem;
    margin-top: 1rem;
  }
  
  .login-form {
    padding: 1.5rem;
    -ms-flex-preferred-size: 100vw;
    flex-basis: 100vw;
  }
  
  .login-form .login-heading {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }
  
  .or {
    margin: 1rem 0;
  }
  
  .login-form label {
    display: block;
    margin: 1.25rem 0 1rem 0;
    
  }
  
  .login-form input {
    width: 100%;
    padding: 15px;
    background-color: #f1f9ff;
    border: 2px solid #ff00ba;
    border-radius: 8px;
  }
  
  .login-form button {
    height: 40px;
    width: 100%;
    background-color: #ff00ba;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: none;
    display: block;
    margin: 0 auto;
    font-weight: bold;
    margin-top: 1.5rem;
    border-radius: 8px;
  }

  .signUp .illustration {
    order: 2;
    justify-self: flex-end;
    margin-left: 2rem;
  }
  
  button:hover {
    filter: brightness(95%);
  }
  
  button:active {
    transform: scale(0.98);
  }


  @media (min-width: 542px) {
    /* body {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    } */
    .login-wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      height: 100vh;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      padding: 1.5rem;
    }
    .login-form {
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }
    .login-form input {
      width: 250px;
    }
  }