.measurement-toolbar {
  position: absolute;
  bottom: 45px;
  right: 20px;
  display: flex;
  flex-direction: row;
  z-index: 1;
  display: flex;
  flex-direction: column;
}
.elevation-marker {
  color: black;
  line-height: 1.8;
  text-align: center;
  font-family: "monospace";
  font-weight: bold;
  position: absolute;
  width: 55px;
  height: 25px;
  border-radius: 10px;
  border: 1px solid cyan;
  background: white;
  visibility: hidden;
  z-index: 0;
}
.visible-show {
  visibility: show;
}
.visible-hide {
  visibility: hidden;
}
.measure-btn-width {
  width: 200px;
}
