.sectionplane-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #788b93;
  justify-content: space-between;
  padding: 3px 15px 3px 15px;
  border-bottom: 1px solid black;
}
.sectionplane-item:hover {
  background-color: #aab6bb;
}
.sectionplane-item-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
/* .sectionplane-item-lock:hover {
  content: url(../../asset/unlock.png);
} */
.lock-btn {
}
