.toolbar {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 300px;
  height: 35px;
  background-color: #3f3f3f;
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 50px;
  padding-top: 5px;
  padding-left: 50px;
  border-top: 1px solid black;
}
.measurebtn-show:hover {
  content: url(../../asset/ruler_hover.png);
  width: 30px;
}
.sectioncutbtn-show:hover {
  content: url(../../asset/sectioncut_hover.png);
  width: 35px;
}
.measurebtn-show {
  visibility: show;
}
.measurebtn-hide {
  visibility: hidden;
}
.sectioncutbtn-show {
  visibility: show;
}
.sectioncutbtn-hide {
  visibility: hidden;
}
.selectentitybtn-hide {
  visibility: hidden;
}
.selectentitybtn-show {
  visibility: show;
}
.saveView:hover {
  content: url(../../asset/save_hover.png);
}
.resetSection {
}
.save-viewer-tab {
  position: fixed;
  top: 35px;
  right: 0px;
  height: 100%;
  z-index: 3;
}
.selectentitybtn-show:hover {
  content: url(../../asset/select_hover.png);
}
