.detail-tab {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 550px;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 2px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.detail-tab-list {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  padding: 8px 2px 8px 2px;
  border-bottom: 1px solid #1D1D1D;
  min-height: 50px;
}

.detail-tab-list-name {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 30%;
}

.detail-tab-list-markup {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.detail-tab-list-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  width: 70%;
  gap: 10px;
}

.detail-tab-list-edit:hover {
  content: url(../../asset/edit_variant.svg);
}

.detail-tab-title {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100px;
  justify-content: center;
  align-items: center;
  background-color: #232323;
  border: 1px solid #434343;
  z-index: 1;
  gap: 10px;
  box-sizing: border-box;
  padding: 3px;
}

.detail-markup-img {
  background-color: lightblue;
  border: 1px solid #FF00BA;
  border-radius: 5px;
}

.detail-tab-title-input {
  width: 70%;
}

.detail-tab-stamp {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100px;
  justify-content: center;
  align-items: center;
  background-color: #232323;
  border: 1px solid #434343;
  z-index: 1;
  gap: 10px;
  box-sizing: border-box;
  padding: 3px;
}

.detail-tab-status {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 130px;
  justify-content: center;
  align-items: center;
  background-color: #232323;
  z-index: 1;
  border: 1px solid #434343;
  gap: 10px;
  box-sizing: border-box;
  padding: 3px;
}

.detail-tab-type {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 130px;
  justify-content: center;
  align-items: center;
  background-color: #232323;
  z-index: 1;
  border: 1px solid #434343;
  gap: 10px;
  box-sizing: border-box;
  padding: 3px;
}

.detail-tab-list-assignee-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  width: 100%;
}

.detail-tab-list-assignee {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 150px;
}

.detail-tab-assignee {
  width: 100%;
  height: 150px;
  background-color: #232323;
  z-index: 1;
  border: 1px solid #434343;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 5px 0px 5px 0px;
}

.detail-tab-assign-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.detail-tab-assign-group {
  border-bottom: 1px solid #434343;
  width: 100%;
  height: 100%;
}

.detail-tab-box-assignee {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.detail-tab-btn {
  width: 100%;
  box-sizing: border-box;
  padding: 2px 60px 2px 60px;
}

.detail-markup-container {
  height: calc(80vh - 112px);
  box-sizing: border-box;
  overflow-y: auto;
}

.detail-markup-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  gap: 15px;
  padding: 10px;
  /* height: 100%; */
}

.detail-markup-modal-header {
  width: 500px;
  height: 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  box-sizing: border-box;
}

.detail-markup-modal-body {
  width: 100%;
  height: 80%;
}

.image-editor {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 21;
}

.update-markup-btn {
  position: fixed;
  top: 75px;
  right: 100px;
}