.toggle-container {
  text-align: center;
}

.toggle-switch {
  position: relative;
  width: 28px;
  display: inline-block;
  text-align: left;
  top: 3px;
}

.checkbox {
  display: none;
}

.label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid #BFBFBF;
  border-radius: 8px;
}

.inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.1s ease-in 0s;
}

.inner:before,
.inner:after {
  float: left;
  width: 50%;
  height: 14px;
  padding: 0;
  box-sizing: border-box;
  background-color: #232323;
}

.inner:before {
  content: "";
}

.inner:after {
  content: "";
}

.switch {
  display: block;
  width: 8px;
  height: 8px;
  margin: 5px;
  position: absolute;
  background-color: #BFBFBF;
  top: 0;
  bottom: 0;
  right: 10px;
  border-radius: 8px;
  transition: all 0.1s ease-in 0s;
}

.checkbox:checked+.label .inner {
  margin-left: 0;
}

.checkbox:checked+.label .switch {
  right: 0px;
  background-color: #FF00BA;
}