.tab {
  display: flex;
  flex-direction: column;
  width: 450px;
  gap: 10px;
  height: 100%;
  background-color: white;
  align-items: center;
  overflow-y: scroll;
}

.tab-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bold;
  width: 95%;
}

.tab-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.tab-header-cancel-btn {
  width: 40px;
}

.tab-header-cancel-btn:hover {
  background-color: #ddd;
}
