.table-row:hover {
  background-color: #FF00BA;
}

.expand-icon {
  width: 6px;
  height: 6px;
  border-right: 1px solid #BFBFBF;
  border-bottom: 1px solid #BFBFBF;
  transform: rotate(-45deg);
  border: 1 0 0 1;
}

.email-text {
  text-transform: lowercase;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
  font-size: 14px;
}

.box-property-container {
  display: flex;
  flex-direction: column;
  color: #BFBFBF;
  width: 90%;
  text-align: start;
  gap: 5px;
  margin-top: 5px;
}

.box-btn-container {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  width: 90%;
  gap: 10px;
}

.saveBox-btn-container {
  display: flex;
  justify-content: start;
  width: 40%;
  margin-top: 8px;
}

.input-error {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #FF7F7F;
  margin: 3px;
}