.annotation-tab {
  position: absolute;
  left: 0px;
  height: 100%;
}

.issue-type {
  /* color: red; */
  font-weight: 500;
  border: 2px solid;
  border-radius: 10px;
  padding: 1px 8px 1px 8px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.issue-type-clicked {
  /* color: red; */
  font-weight: 500;
  border: 2px solid;
  border-radius: 10px;
  padding: 1px 8px 1px 8px;
  background-color: #cccccc;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.annotation-tab-body {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: center;
}

.annotation-marker {
  line-height: 1.8;
  text-align: center;
  font-family: "monospace";
  font-weight: bold;
  position: absolute;
  width: 45px;
  height: 25px;
  border-radius: 10px;
  border: 4px solid;
  background: white;
  visibility: hidden;
  box-shadow: 5px 5px 15px 1px #000000;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.issue-type:hover {
  background-color: #cccccc;
}

.issue-type:focus {
  background-color: black;
}

.annotation-option-tab {
  position: absolute;
  right: 0;
  height: 100%;
}

#snapshots {
  position: absolute;
  left: 100px;
  top: 60px;
  margin-top: 20px;
  /* overflow-y: scroll; */
  height: calc(100% - 120px);
  margin-bottom: 10px;
  width: 230px;
  pointer-events: all;
  opacity: 0.8;
}

/* #snapshot {
  padding: 0;
  margin: 0;
  position: absolute;
  height: 90%;
  width: 98%;
  left: 10px;
  top: 30px;
  background: lightblue;
  z-index: 11;
  border: 2px black solid;
} */
#annotationsContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  pointer-events: none;
  z-index: 10;
}


.annotation-issue-container {
  overflow-y: auto;
  height: calc(80vh - 120px);
}

.annotation-issue-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  gap: 15px;
  padding: 10px;
}

.issue-modal-title {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 100%;
  gap: 2px;
}

.issue-modal-title-input {
  width: 100%;
}

.issue-modal-textarea {
  width: 97%;
  background-color: #232323;
  border-radius: 5px;
  min-height: 50px;
  color: #BFBFBF;
}

.image-editor {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 21;
}
