/* Grid container */
.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1200px;
  /* Adjust for your design */
}

/* Row */
.row {
  --bs-guter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}

/* Columns */
[class*="col-"] {
  padding: 0 15px;
  flex: 1;
}

/* Column widths for responsiveness */
.col-1 {
  flex: 0 0 8.33%;
  max-width: 8.33%;
}

.col-2 {
  flex: 0 0 16.66%;
  max-width: 16.66%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33%;
  max-width: 33.33%;
}

.col-5 {
  flex: 0 0 41.66%;
  max-width: 41.66%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33%;
  max-width: 58.33%;
}

.col-8 {
  flex: 0 0 66.66%;
  max-width: 66.66%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33%;
  max-width: 83.33%;
}

.col-11 {
  flex: 0 0 91.66%;
  max-width: 91.66%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

/* 
@media (max-width: 768px) {
  .col-sm-1 { flex: 0 0 8.33%; max-width: 8.33%; }
  .col-sm-2 { flex: 0 0 16.66%; max-width: 16.66%; }
  .col-sm-3 { flex: 0 0 25%; max-width: 25%; }
  .col-sm-4 { flex: 0 0 33.33%; max-width: 33.33%; }
  .col-sm-6 { flex: 0 0 50%; max-width: 50%; }
  .col-sm-12 { flex: 0 0 100%; max-width: 100%; }
}

@media (max-width: 576px) {
  .col-xs-12 { flex: 0 0 100%; max-width: 100%; }
} */


/* Flexbox container */
.flex {
  display: flex;
}

/* Flex directions */
.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

/* Justify content */
.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

/* Align items */
.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}

.items-stretch {
  align-items: stretch;
}

/* Align self */
.self-start {
  align-self: flex-start;
}

.self-center {
  align-self: center;
}

.self-end {
  align-self: flex-end;
}

.self-stretch {
  align-self: stretch;
}

/* Flex grow/shrink */
.flex-grow {
  flex-grow: 1;
}

.flex-shrink {
  flex-shrink: 1;
}

.no-grow {
  flex-grow: 0;
}

.no-shrink {
  flex-shrink: 0;
}


/* Margin */
.m-0 {
  margin: 0;
}

.mt-1 {
  margin-top: 5px;
}

.mt-2 {
  margin-top: 10px;
}

.mt-3 {
  margin-top: 15px;
}

.mt-4 {
  margin-top: 20px;
}

.mt-5 {
  margin-top: 25px;
}

.m-auto {
  margin: auto;
}

/* Padding */
.p-0 {
  padding: 0;
}

.pt-1 {
  padding-top: 5px;
}

.pt-2 {
  padding-top: 10px;
}

.pt-3 {
  padding-top: 15px;
}

.pt-4 {
  padding-top: 20px;
}

.pt-5 {
  padding-top: 25px;
}

.p-auto {
  padding: auto;
}

/* Gaps for flex/grid */
.gap-1 {
  gap: 5px;
}

.gap-2 {
  gap: 10px;
}

.gap-3 {
  gap: 15px;
}

.gap-4 {
  gap: 20px;
}


.d-block {
  display: block;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.d-grid {
  display: grid;
}

.d-none {
  display: none;
}


.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

/* Visibility */
.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

/* Overflow */
.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

/* Positioning */
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
  top: 0;
}

/* Width */
.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.w-10 {
  width: 10%;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.max-w-sm {
  max-width: 640px;
}

.max-w-md {
  max-width: 768px;
}

.max-w-lg {
  max-width: 1024px;
}

.max-w-full {
  max-width: 100%;
}

/* Height */
.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.min-h-full {
  min-height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}


/* Borders */
.border {
  border: 1px solid #ccc;
}

.border-0 {
  border: none;
}

.border-top {
  border-top: 1px solid #ccc;
}

.border-right {
  border-right: 1px solid #ccc;
}

.border-bottom {
  border-bottom: 1px solid #ccc;
}

.border-left {
  border-left: 1px solid #ccc;
}

.rounded {
  border-radius: 5px;
}

.rounded-sm {
  border-radius: 3px;
}

.rounded-lg {
  border-radius: 10px;
}

.rounded-full {
  border-radius: 50%;
}

/* Border Colors */
.border-primary {
  border-color: #007bff;
}

.border-secondary {
  border-color: #6c757d;
}

.border-success {
  border-color: #28a745;
}

.border-danger {
  border-color: #dc3545;
}

.border-warning {
  border-color: #ffc107;
}

/* Background */
.bg-white {
  background-color: #ffffff;
}

.bg-black {
  background-color: #000000;
}

.bg-gray {
  background-color: #f8f9fa;
}

.bg-primary {
  background-color: #007bff;
}

.bg-secondary {
  background-color: #6c757d;
}

.bg-success {
  background-color: #28a745;
}

.bg-danger {
  background-color: #dc3545;
}

.bg-warning {
  background-color: #ffc107;
}

.bg-transparent {
  background-color: transparent;
}

/* Background Hover */
.bg-hover-primary:hover {
  background-color: #0056b3;
}

.bg-hover-secondary:hover {
  background-color: #5a6268;
}

.bg-hover-success:hover {
  background-color: #218838;
}

.bg-hover-danger:hover {
  background-color: #c82333;
}

.bg-hover-warning:hover {
  background-color: #e0a800;
}

/* Text Transform */
.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

/* Font Weight */
.font-thin {
  font-weight: 100;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

.font-black {
  font-weight: 900;
}

/* Font Size */
.text-xs {
  font-size: 0.75rem;
}

.text-sm {
  font-size: 0.875rem;
}

.text-md {
  font-size: 1rem;
}

.text-lg {
  font-size: 1.25rem;
}

.text-xl {
  font-size: 1.5rem;
}

.text-2xl {
  font-size: 2rem;
}

.text-3xl {
  font-size: 3rem;
}

/* Hover Colors */
.text-hover-primary:hover {
  color: #007bff;
}

.text-hover-danger:hover {
  color: #dc3545;
}

.text-hover-success:hover {
  color: #28a745;
}

/* Opacity */
.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-100 {
  opacity: 1;
}

/* Hover Opacity */
.opacity-hover-50:hover {
  opacity: 0.5;
}

.opacity-hover-75:hover {
  opacity: 0.75;
}

/* Z-Index */
.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-50 {
  z-index: 50;
}

.z-auto {
  z-index: auto;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #BFBFBF;
  border-radius: 10px;
}