.xeokitViewer {
  width: 100%;
  height: 100vh;
}

/* .annotation-marker {
  color: #ffffff;
  line-height: 1.8;
  text-align: center;
  font-family: "monospace";
  font-weight: bold;
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 15px;
  border: 2px solid #ffffff;
  background: black;
  visibility: hidden;
  box-shadow: 5px 5px 15px 1px #000000;
  z-index: 0;
} */

.annotation-label {
  position: absolute;
  max-width: 250px;
  min-height: 50px;
  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
  background: #ffffff;
  color: #000000;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 8px;
  border: #ffffff solid 2px;
  box-shadow: 5px 5px 15px 1px #000000;
  z-index: 90000;
}

.annotation-label:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 8px 12px 8px 0;
  border-color: transparent white;
  display: block;
  width: 0;
  z-index: 1;
  margin-top: -11px;
  left: -12px;
  top: 20px;
}

.annotation-label:before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 9px 13px 9px 0;
  border-color: transparent #ffffff;
  display: block;
  width: 0;
  z-index: 0;
  margin-top: -12px;
  left: -15px;
  top: 20px;
}

.annotation-title {
  font: normal 20px arial, serif;
  margin-bottom: 8px;
}

.annotation-desc {
  font: normal 14px arial, serif;
}

#myNavCubeCanvas {
  position: absolute;
  width: 250px;
  height: 250px;
  bottom: 100px;
  right: 50px;
  z-index: 200000;
}

#storeys {
  position: absolute;
  left: 10px;
  top: 60px;
  margin-top: 20px;
  overflow-y: scroll;
  height: calc(100% - 120px);
  margin-bottom: 10px;
  width: 400px;
  pointer-events: all;
  opacity: 0.8;
}

/* ----------------------------------------------------------------------------------------------------------*/
/* ContextMenu */
/* ----------------------------------------------------------------------------------------------------------*/

.xeokit-context-menu {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  display: none;
  z-index: 300000;
  background: rgba(255, 255, 255, 0.46);
  border: 1px solid black;
  border-radius: 6px;
  padding: 0;
  width: 200px;
}

.xeokit-context-menu ul {
  list-style: none;
  margin-left: 0;
  padding: 0;
}

.xeokit-context-menu-item {
  list-style-type: none;
  padding-left: 10px;
  padding-right: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: black;
  background: rgba(255, 255, 255, 0.46);
  cursor: pointer;
  width: calc(100% - 30px);
}

.xeokit-context-menu-item:hover {
  background: black;
  color: white;
  font-weight: normal;
}

.xeokit-context-menu-item span {
  display: inline-block;
}

.xeokit-context-menu .disabled {
  display: inline-block;
  color: gray;
  cursor: default;
  font-weight: normal;
}

.xeokit-context-menu .disabled:hover {
  color: gray;
  cursor: default;
  background: #eeeeee;
  font-weight: normal;
}

.xeokit-context-menu-item-separator {
  background: rgba(0, 0, 0, 1);
  height: 1px;
  width: 100%;
}

/* #mySectionPlanesOverviewCanvas {
  position: absolute;
  width: 250px;
  height: 250px;
  top: 70px;
  right: 10px;
  z-index: 200000;
} */
.xeokit-camera-pivot-marker {
  color: #ffffff;
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 15px;
  border: 2px solid #ebebeb;
  background: black;
  visibility: hidden;
  box-shadow: 5px 5px 15px 1px #000000;
  z-index: 10000;
  pointer-events: none;
}

#storeys {
  position: absolute;
  left: 10px;
  top: 60px;
  margin-top: 20px;
  overflow-y: scroll;
  height: calc(100% - 120px);
  margin-bottom: 10px;
  width: 600px;
  pointer-events: all;
  opacity: 1;
}

/* Custom spinner CSS */

#mySpinnerElement {
  z-index: 9000;
  position: absolute;
  left: 500px;
  top: 500px;
  visibility: hidden;
}

.sk-fading-circle {
  background: transparent;
  margin: 20px auto;
  width: 50px;
  height: 50px;
  position: relative;
}

.sk-fading-circle .sk-circle {
  width: 120%;
  height: 120%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #FF00BA;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}

.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {

  0%,
  39%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}

@keyframes sk-circleFadeDelay {

  0%,
  39%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}